<template>
  <div />
</template>

<script>
import { useReferral } from '@/views/apps/referral/useReferral'

export default {

  setup() {
    const {
      refQueryHandler,
    } = useReferral()

    refQueryHandler()
    return {
    }
  },
}
</script>
